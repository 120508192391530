import { __values } from "tslib";
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { v4 as uuidV4 } from 'uuid';
import { Event } from './Event';
import { EventChecker } from './EventChecker';
import { BrowserInfo } from '../browser';
import config from '../config';
import { StorageUtil } from '../util/StorageUtil';
var sdkVersion = config.sdkVersion;
var AnalyticsEventBuilder = /** @class */ (function () {
    function AnalyticsEventBuilder() {
    }
    AnalyticsEventBuilder.createEvent = function (context, event, userAttributes, globalAttributes, session) {
        if (globalAttributes === void 0) { globalAttributes = {}; }
        var browserInfo = context.browserInfo, configuration = context.configuration;
        var attributes = this.getEventAttributesWithCheck(event.attributes, globalAttributes);
        if (session !== undefined) {
            attributes[Event.ReservedAttribute.SESSION_ID] = session.sessionId;
            attributes[Event.ReservedAttribute.SESSION_START_TIMESTAMP] =
                session.startTime;
            attributes[Event.ReservedAttribute.SESSION_DURATION] =
                session.getDuration();
            attributes[Event.ReservedAttribute.SESSION_NUMBER] = session.sessionIndex;
        }
        attributes[Event.ReservedAttribute.PAGE_TITLE] =
            BrowserInfo.getCurrentPageTitle();
        attributes[Event.ReservedAttribute.PAGE_URL] =
            BrowserInfo.getCurrentPageUrl();
        attributes[Event.ReservedAttribute.LATEST_REFERRER] =
            browserInfo.latestReferrer;
        attributes[Event.ReservedAttribute.LATEST_REFERRER_HOST] =
            browserInfo.latestReferrerHost;
        var items = this.getEventItemsWithCheck(event.items, attributes);
        return {
            event_type: event.name,
            event_id: uuidV4(),
            device_id: StorageUtil.getDeviceId(),
            unique_id: context.userUniqueId,
            app_id: configuration.appId,
            timestamp: new Date().getTime(),
            host_name: browserInfo.hostName,
            locale: browserInfo.locale,
            system_language: browserInfo.system_language,
            country_code: browserInfo.country_code,
            zone_offset: browserInfo.zoneOffset,
            make: browserInfo.make,
            platform: 'Web',
            screen_height: window.screen.height,
            screen_width: window.screen.width,
            viewport_height: window.innerHeight,
            viewport_width: window.innerWidth,
            sdk_name: 'aws-solution-clickstream-sdk',
            sdk_version: sdkVersion,
            items: items,
            user: userAttributes !== null && userAttributes !== void 0 ? userAttributes : {},
            attributes: attributes,
        };
    };
    AnalyticsEventBuilder.getEventAttributesWithCheck = function (eventAttributes, globalAttributes) {
        if (globalAttributes === void 0) { globalAttributes = {}; }
        var customAttributes = {};
        var checkAttributes = EventChecker.checkAttributes;
        var globalAttributesLength = Object.keys(globalAttributes).length;
        for (var key in eventAttributes) {
            var value = eventAttributes[key];
            if (value !== null) {
                var currentNumber = Object.keys(customAttributes).length + globalAttributesLength;
                var result = checkAttributes(currentNumber, key, value);
                var _a = Event.ReservedAttribute, ERROR_CODE = _a.ERROR_CODE, ERROR_MESSAGE = _a.ERROR_MESSAGE;
                if (result.error_code > 0) {
                    customAttributes[ERROR_CODE] = result.error_code;
                    customAttributes[ERROR_MESSAGE] = result.error_message;
                }
                else {
                    customAttributes[key] = value;
                }
            }
        }
        return Object.assign(customAttributes, globalAttributes);
    };
    AnalyticsEventBuilder.getEventItemsWithCheck = function (items, attributes) {
        var e_1, _a;
        var resultItems = undefined;
        if (items !== undefined) {
            resultItems = [];
            var checkItems = EventChecker.checkItems;
            try {
                for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item = items_1_1.value;
                    var result = checkItems(resultItems.length, item);
                    var _b = Event.ReservedAttribute, ERROR_CODE = _b.ERROR_CODE, ERROR_MESSAGE = _b.ERROR_MESSAGE;
                    if (result.error_code > 0) {
                        attributes[ERROR_CODE] = result.error_code;
                        attributes[ERROR_MESSAGE] = result.error_message;
                    }
                    if (result.error_code !== Event.ErrorCode.ITEM_SIZE_EXCEED) {
                        resultItems.push(item);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return resultItems;
    };
    return AnalyticsEventBuilder;
}());
export { AnalyticsEventBuilder };
