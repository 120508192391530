/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { v4 as uuidV4 } from 'uuid';
import { Event } from '../provider';
var logger = new Logger('StorageUtil');
var StorageUtil = /** @class */ (function () {
    function StorageUtil() {
    }
    StorageUtil.getDeviceId = function () {
        var _b;
        var deviceId = (_b = localStorage.getItem(StorageUtil.deviceIdKey)) !== null && _b !== void 0 ? _b : '';
        if (deviceId === '') {
            deviceId = uuidV4();
            localStorage.setItem(StorageUtil.deviceIdKey, deviceId);
        }
        return deviceId;
    };
    StorageUtil.setCurrentUserUniqueId = function (userUniqueId) {
        localStorage.setItem(StorageUtil.userUniqueIdKey, userUniqueId);
    };
    StorageUtil.getCurrentUserUniqueId = function () {
        var _b;
        var userUniqueId = (_b = localStorage.getItem(StorageUtil.userUniqueIdKey)) !== null && _b !== void 0 ? _b : '';
        if (userUniqueId === '') {
            userUniqueId = uuidV4();
            StorageUtil.setCurrentUserUniqueId(userUniqueId);
            localStorage.setItem(StorageUtil.userUniqueIdKey, userUniqueId);
            StorageUtil.saveUserFirstTouchTimestamp();
        }
        return userUniqueId;
    };
    StorageUtil.saveUserFirstTouchTimestamp = function () {
        var _b;
        var firstTouchTimestamp = new Date().getTime();
        localStorage.setItem(StorageUtil.userFirstTouchTimestampKey, String(firstTouchTimestamp));
        StorageUtil.updateUserAttributes((_b = {},
            _b[Event.ReservedAttribute.USER_FIRST_TOUCH_TIMESTAMP] = {
                value: firstTouchTimestamp,
                set_timestamp: firstTouchTimestamp,
            },
            _b));
    };
    StorageUtil.saveUserIdMapping = function (userIdMappingObject) {
        localStorage.setItem(StorageUtil.userIdMappingKey, JSON.stringify(userIdMappingObject));
    };
    StorageUtil.getUserIdMapping = function () {
        return JSON.parse(localStorage.getItem(StorageUtil.userIdMappingKey));
    };
    StorageUtil.getUserInfoFromMapping = function (userId) {
        var _b, _c;
        var userIdMapping = StorageUtil.getUserIdMapping();
        var userInfo;
        var timestamp = new Date().getTime();
        if (userIdMapping === null) {
            userIdMapping = {};
            userInfo = (_b = {
                    user_uniqueId: {
                        value: StorageUtil.getCurrentUserUniqueId(),
                        set_timestamp: timestamp,
                    }
                },
                _b[Event.ReservedAttribute.USER_FIRST_TOUCH_TIMESTAMP] = StorageUtil.getUserAttributes()[Event.ReservedAttribute.USER_FIRST_TOUCH_TIMESTAMP],
                _b);
        }
        else if (userId in userIdMapping) {
            userInfo = userIdMapping[userId];
            StorageUtil.setCurrentUserUniqueId(userInfo.user_uniqueId.value.toString());
        }
        else {
            var userUniqueId = uuidV4();
            StorageUtil.setCurrentUserUniqueId(userUniqueId);
            userInfo = (_c = {
                    user_uniqueId: {
                        value: userUniqueId,
                        set_timestamp: timestamp,
                    }
                },
                _c[Event.ReservedAttribute.USER_FIRST_TOUCH_TIMESTAMP] = {
                    value: timestamp,
                    set_timestamp: timestamp,
                },
                _c);
        }
        userIdMapping[userId] = userInfo;
        StorageUtil.saveUserIdMapping(userIdMapping);
        return userInfo;
    };
    StorageUtil.getBundleSequenceId = function () {
        var _b;
        return parseInt((_b = localStorage.getItem(StorageUtil.bundleSequenceIdKey)) !== null && _b !== void 0 ? _b : '1');
    };
    StorageUtil.saveBundleSequenceId = function (bundleSequenceId) {
        localStorage.setItem(StorageUtil.bundleSequenceIdKey, String(bundleSequenceId));
    };
    StorageUtil.updateUserAttributes = function (userAttributes) {
        localStorage.setItem(StorageUtil.userAttributesKey, JSON.stringify(userAttributes));
    };
    StorageUtil.getUserAttributes = function () {
        var _b;
        var userAttributes = (_b = localStorage.getItem(StorageUtil.userAttributesKey)) !== null && _b !== void 0 ? _b : '{}';
        return JSON.parse(userAttributes);
    };
    StorageUtil.getFailedEvents = function () {
        var _b;
        return (_b = localStorage.getItem(StorageUtil.failedEventsKey)) !== null && _b !== void 0 ? _b : '';
    };
    StorageUtil.saveFailedEvent = function (event) {
        var MAX_FAILED_EVENTS_SIZE = StorageUtil.MAX_FAILED_EVENTS_SIZE;
        var allEvents = StorageUtil.getFailedEvents();
        var eventsStr = '';
        if (allEvents === '') {
            eventsStr = Event.Constants.PREFIX + JSON.stringify(event);
        }
        else {
            eventsStr = allEvents + ',' + JSON.stringify(event);
        }
        if (eventsStr.length <= MAX_FAILED_EVENTS_SIZE) {
            localStorage.setItem(StorageUtil.failedEventsKey, eventsStr);
        }
        else {
            var maxSize = MAX_FAILED_EVENTS_SIZE / 1024;
            logger.warn("Failed events reached max cache size of ".concat(maxSize, "kb"));
        }
    };
    StorageUtil.clearFailedEvents = function () {
        localStorage.removeItem(StorageUtil.failedEventsKey);
    };
    StorageUtil.getAllEvents = function () {
        var _b;
        return (_b = localStorage.getItem(StorageUtil.eventsKey)) !== null && _b !== void 0 ? _b : '';
    };
    StorageUtil.saveEvent = function (event) {
        var MAX_BATCH_EVENTS_SIZE = StorageUtil.MAX_BATCH_EVENTS_SIZE;
        var allEvents = StorageUtil.getAllEvents();
        var eventsStr = '';
        if (allEvents === '') {
            eventsStr = Event.Constants.PREFIX + JSON.stringify(event);
        }
        else {
            eventsStr = allEvents + ',' + JSON.stringify(event);
        }
        if (eventsStr.length <= MAX_BATCH_EVENTS_SIZE) {
            localStorage.setItem(StorageUtil.eventsKey, eventsStr);
            return true;
        }
        else {
            var maxSize = MAX_BATCH_EVENTS_SIZE / 1024;
            logger.warn("Events reached max cache size of ".concat(maxSize, "kb"));
            return false;
        }
    };
    StorageUtil.clearEvents = function (eventsJson) {
        var eventsString = this.getAllEvents();
        if (eventsString === '')
            return;
        var deletedEvents = JSON.parse(eventsJson);
        var allEvents = JSON.parse(this.getAllEvents() + Event.Constants.SUFFIX);
        if (allEvents.length > deletedEvents.length) {
            var leftEvents = allEvents.splice(deletedEvents.length);
            var leftEventsStr = JSON.stringify(leftEvents);
            leftEventsStr = leftEventsStr.substring(0, leftEventsStr.length - 1);
            localStorage.setItem(StorageUtil.eventsKey, leftEventsStr);
        }
        else {
            localStorage.removeItem(StorageUtil.eventsKey);
        }
    };
    StorageUtil.clearAllEvents = function () {
        localStorage.removeItem(StorageUtil.eventsKey);
    };
    StorageUtil.saveSession = function (session) {
        localStorage.setItem(StorageUtil.sessionKey, JSON.stringify(session));
    };
    StorageUtil.getSession = function () {
        var sessionStr = localStorage.getItem(StorageUtil.sessionKey);
        if (sessionStr === null) {
            return null;
        }
        return JSON.parse(sessionStr);
    };
    StorageUtil.getIsFirstOpen = function () {
        return localStorage.getItem(StorageUtil.isFirstOpenKey) === null;
    };
    StorageUtil.saveIsFirstOpenToFalse = function () {
        localStorage.setItem(StorageUtil.isFirstOpenKey, '0');
    };
    StorageUtil.getPreviousPageUrl = function () {
        var _b;
        return (_b = sessionStorage.getItem(StorageUtil.previousPageUrlKey)) !== null && _b !== void 0 ? _b : '';
    };
    StorageUtil.savePreviousPageUrl = function (url) {
        sessionStorage.setItem(StorageUtil.previousPageUrlKey, url);
    };
    StorageUtil.getPreviousPageTitle = function () {
        var _b;
        return (_b = sessionStorage.getItem(StorageUtil.previousPageTitleKey)) !== null && _b !== void 0 ? _b : '';
    };
    StorageUtil.savePreviousPageTitle = function (title) {
        sessionStorage.setItem(StorageUtil.previousPageTitleKey, title);
    };
    StorageUtil.getPreviousPageStartTime = function () {
        var startTime = localStorage.getItem(StorageUtil.previousPageStartTimeKey);
        if (startTime === null) {
            return 0;
        }
        else {
            return Number(startTime);
        }
    };
    StorageUtil.savePreviousPageStartTime = function (timestamp) {
        localStorage.setItem(StorageUtil.previousPageStartTimeKey, timestamp.toString());
    };
    var _a;
    _a = StorageUtil;
    StorageUtil.MAX_REQUEST_EVENTS_SIZE = 1024 * 512;
    StorageUtil.MAX_FAILED_EVENTS_SIZE = _a.MAX_REQUEST_EVENTS_SIZE;
    StorageUtil.MAX_BATCH_EVENTS_SIZE = 1024 * 1024;
    StorageUtil.prefix = 'aws-solution/clickstream-web/';
    StorageUtil.deviceIdKey = _a.prefix + 'deviceIdKey';
    StorageUtil.userUniqueIdKey = _a.prefix + 'userUniqueIdKey';
    StorageUtil.bundleSequenceIdKey = _a.prefix + 'bundleSequenceIdKey';
    StorageUtil.userAttributesKey = _a.prefix + 'userAttributesKey';
    StorageUtil.userFirstTouchTimestampKey = _a.prefix + 'userFirstTouchTimestampKey';
    StorageUtil.failedEventsKey = _a.prefix + 'failedEventsKey';
    StorageUtil.eventsKey = _a.prefix + 'eventsKey';
    StorageUtil.sessionKey = _a.prefix + 'sessionKey';
    StorageUtil.isFirstOpenKey = _a.prefix + 'isFirstOpenKey';
    StorageUtil.previousPageUrlKey = _a.prefix + 'previousPageUrlKey';
    StorageUtil.previousPageTitleKey = _a.prefix + 'previousPageTitleKey';
    StorageUtil.previousPageStartTimeKey = _a.prefix + 'previousPageStartTimeKey';
    StorageUtil.userIdMappingKey = _a.prefix + 'userIdMappingKey';
    return StorageUtil;
}());
export { StorageUtil };
