import { __extends } from "tslib";
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { Logger } from '@aws-amplify/core';
import { BaseTracker } from './BaseTracker';
import { Session } from './Session';
import { BrowserInfo } from '../browser';
import { Event } from '../provider';
import { PageType } from '../types';
import { StorageUtil } from '../util/StorageUtil';
var logger = new Logger('SessionTracker');
var SessionTracker = /** @class */ (function (_super) {
    __extends(SessionTracker, _super);
    function SessionTracker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isWindowClosing = false;
        return _this;
    }
    SessionTracker.prototype.init = function () {
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.onBeforeUnload = this.onBeforeUnload.bind(this);
        this.handleInit();
        if (!this.checkEnv()) {
            logger.warn('not supported env');
        }
        else {
            document.addEventListener(this.visibilityChange, this.onVisibilityChange, false);
            window.addEventListener('beforeunload', this.onBeforeUnload, false);
        }
    };
    SessionTracker.prototype.onVisibilityChange = function () {
        if (document.visibilityState === this.hiddenStr) {
            this.onPageHide();
        }
        else {
            this.onPageAppear();
        }
    };
    SessionTracker.prototype.handleInit = function () {
        if (StorageUtil.getIsFirstOpen()) {
            this.provider.record({
                name: Event.PresetEvent.FIRST_OPEN,
            });
            StorageUtil.saveIsFirstOpenToFalse();
        }
        this.onPageAppear(true);
    };
    SessionTracker.prototype.onPageAppear = function (isFirstTime) {
        var _a;
        if (isFirstTime === void 0) { isFirstTime = false; }
        logger.debug('page appear');
        var pageViewTracker = this.provider.pageViewTracker;
        pageViewTracker.updateLastScreenStartTimestamp();
        this.session = Session.getCurrentSession(this.context);
        if (this.session.isNewSession()) {
            pageViewTracker.setIsEntrances();
            this.provider.record({ name: Event.PresetEvent.SESSION_START });
        }
        if (isFirstTime && this.isMultiPageApp() && this.isFromCurrentHost())
            return;
        this.provider.record({
            name: Event.PresetEvent.APP_START,
            attributes: (_a = {},
                _a[Event.ReservedAttribute.IS_FIRST_TIME] = isFirstTime,
                _a),
        });
    };
    SessionTracker.prototype.isFromCurrentHost = function () {
        return window.location.host === this.context.browserInfo.latestReferrerHost;
    };
    SessionTracker.prototype.isMultiPageApp = function () {
        return this.context.configuration.pageType === PageType.multiPageApp;
    };
    SessionTracker.prototype.onPageHide = function () {
        logger.debug('page hide');
        this.storeSession();
        var isImmediate = !(this.isWindowClosing && BrowserInfo.isFirefox());
        this.recordUserEngagement(isImmediate);
        this.recordAppEnd(isImmediate);
        this.provider.sendEventsInBackground(this.isWindowClosing);
    };
    SessionTracker.prototype.recordUserEngagement = function (isImmediate) {
        this.provider.pageViewTracker.recordUserEngagement(isImmediate);
    };
    SessionTracker.prototype.recordAppEnd = function (isImmediate) {
        this.provider.record({
            name: Event.PresetEvent.APP_END,
            isImmediate: isImmediate,
        });
    };
    SessionTracker.prototype.onBeforeUnload = function () {
        logger.debug('onBeforeUnload');
        this.isWindowClosing = true;
    };
    SessionTracker.prototype.storeSession = function () {
        this.session.pause();
        StorageUtil.saveSession(this.session);
    };
    SessionTracker.prototype.checkEnv = function () {
        if (!document || !document.addEventListener) {
            logger.debug('not in the supported web environment');
            return false;
        }
        if (typeof document.hidden !== 'undefined') {
            this.hiddenStr = 'hidden';
            this.visibilityChange = 'visibilitychange';
        }
        else if (typeof document.msHidden !== 'undefined') {
            this.hiddenStr = 'msHidden';
            this.visibilityChange = 'msvisibilitychange';
        }
        else if (typeof document.webkitHidden !== 'undefined') {
            this.hiddenStr = 'webkitHidden';
            this.visibilityChange = 'webkitvisibilitychange';
        }
        else {
            logger.debug('not in the supported web environment');
            return false;
        }
        return true;
    };
    return SessionTracker;
}(BaseTracker));
export { SessionTracker };
