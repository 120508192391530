/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { __read, __spreadArray } from "tslib";
var MethodEmbed = /** @class */ (function () {
    function MethodEmbed(context, methodName) {
        this.context = context;
        this.methodName = methodName;
        this.originalMethod = context[methodName].bind(context);
    }
    MethodEmbed.add = function (context, methodName, methodOverride) {
        new MethodEmbed(context, methodName).set(methodOverride);
    };
    MethodEmbed.prototype.set = function (methodOverride) {
        var _this = this;
        this.context[this.methodName] = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return methodOverride(_this.originalMethod.apply(_this, __spreadArray([], __read(args), false)));
        };
    };
    return MethodEmbed;
}());
export { MethodEmbed };
