import { StorageUtil } from '../util/StorageUtil';
var Session = /** @class */ (function () {
    function Session(sessionId, sessionIndex, startTime, pauseTime) {
        if (pauseTime === void 0) { pauseTime = undefined; }
        this.sessionId = sessionId;
        this.sessionIndex = sessionIndex;
        this.startTime = startTime;
        this.pauseTime = pauseTime;
    }
    Session.createSession = function (uniqueId, sessionIndex) {
        return new Session(this.getSessionId(uniqueId), sessionIndex, new Date().getTime());
    };
    Session.prototype.isNewSession = function () {
        return this.pauseTime === undefined;
    };
    Session.prototype.getDuration = function () {
        return new Date().getTime() - this.startTime;
    };
    Session.prototype.pause = function () {
        this.pauseTime = new Date().getTime();
    };
    Session.getCurrentSession = function (context) {
        var storedSession = StorageUtil.getSession();
        var sessionIndex = 1;
        if (storedSession !== null) {
            if (new Date().getTime() - storedSession.pauseTime <
                context.configuration.sessionTimeoutDuration) {
                return new Session(storedSession.sessionId, storedSession.sessionIndex, storedSession.startTime, storedSession.pauseTime);
            }
            else {
                sessionIndex = storedSession.sessionIndex + 1;
            }
        }
        return Session.createSession(context.userUniqueId, sessionIndex);
    };
    Session.getSessionId = function (uniqueId) {
        var uniqueIdKey = uniqueId.slice(-Constants.maxUniqueIdLength);
        return "".concat(uniqueIdKey, "-").concat(this.getFormatTime());
    };
    Session.getFormatTime = function () {
        var now = new Date();
        var year = now.getUTCFullYear().toString().padStart(4, '0');
        var month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
        var day = now.getUTCDate().toString().padStart(2, '0');
        var hours = now.getUTCHours().toString().padStart(2, '0');
        var minutes = now.getUTCMinutes().toString().padStart(2, '0');
        var seconds = now.getUTCSeconds().toString().padStart(2, '0');
        var milliseconds = now.getUTCMilliseconds().toString().padStart(3, '0');
        return "".concat(year).concat(month).concat(day, "-").concat(hours).concat(minutes).concat(seconds).concat(milliseconds);
    };
    return Session;
}());
export { Session };
var Constants;
(function (Constants) {
    Constants[Constants["maxUniqueIdLength"] = 8] = "maxUniqueIdLength";
})(Constants || (Constants = {}));
