import { __awaiter, __generator } from "tslib";
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { HashUtil } from '../util/HashUtil';
var logger = new Logger('NetRequest');
var NetRequest = /** @class */ (function () {
    function NetRequest() {
    }
    NetRequest.sendRequest = function (eventsJson, context, bundleSequenceId, retryTimes, timeout) {
        if (retryTimes === void 0) { retryTimes = NetRequest.REQUEST_RETRY_TIMES; }
        if (timeout === void 0) { timeout = NetRequest.REQUEST_TIMEOUT; }
        return __awaiter(this, void 0, void 0, function () {
            var configuration, browserInfo, eventsHash, queryParams, url, controller, timeoutId, inputSizeInBytes, isKeepAlive, requestOptions, retries, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configuration = context.configuration, browserInfo = context.browserInfo;
                        return [4 /*yield*/, HashUtil.getHashCode(eventsJson)];
                    case 1:
                        eventsHash = _a.sent();
                        queryParams = new URLSearchParams({
                            platform: 'Web',
                            appId: configuration.appId,
                            event_bundle_sequence_id: bundleSequenceId.toString(),
                            hashCode: eventsHash,
                        });
                        url = "".concat(configuration.endpoint, "?").concat(queryParams.toString());
                        controller = new AbortController();
                        timeoutId = setTimeout(function () {
                            controller.abort();
                        }, timeout);
                        inputSizeInBytes = new Blob([eventsJson]).size;
                        isKeepAlive = inputSizeInBytes < NetRequest.KEEP_ALIVE_SIZE_LIMIT;
                        requestOptions = {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                cookie: configuration.authCookie,
                                'User-Agent': browserInfo.userAgent,
                            },
                            body: eventsJson,
                            keepalive: isKeepAlive,
                        };
                        requestOptions.signal = controller.signal;
                        retries = 0;
                        _a.label = 2;
                    case 2:
                        if (!(retries < retryTimes)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, 6, 7]);
                        return [4 /*yield*/, fetch(url, requestOptions)];
                    case 4:
                        response = _a.sent();
                        if (response.ok && response.status === 200) {
                            return [2 /*return*/, true];
                        }
                        else {
                            logger.error("Request failed with status code ".concat(response.status));
                        }
                        return [3 /*break*/, 7];
                    case 5:
                        error_1 = _a.sent();
                        logger.error("Error during request: ".concat(error_1));
                        return [3 /*break*/, 7];
                    case 6:
                        clearTimeout(timeoutId);
                        retries++;
                        return [7 /*endfinally*/];
                    case 7: return [3 /*break*/, 2];
                    case 8:
                        logger.error("Request failed after ".concat(retryTimes, " retries"));
                        return [2 /*return*/, false];
                }
            });
        });
    };
    NetRequest.REQUEST_TIMEOUT = 10000;
    NetRequest.BATCH_REQUEST_TIMEOUT = 15000;
    NetRequest.REQUEST_RETRY_TIMES = 3;
    NetRequest.BATCH_REQUEST_RETRY_TIMES = 1;
    NetRequest.KEEP_ALIVE_SIZE_LIMIT = 64 * 1024;
    return NetRequest;
}());
export { NetRequest };
