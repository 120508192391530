import { StorageUtil } from '../util/StorageUtil';
var ClickstreamContext = /** @class */ (function () {
    function ClickstreamContext(browserInfo, configuration) {
        this.browserInfo = browserInfo;
        this.configuration = configuration;
        this.userUniqueId = StorageUtil.getCurrentUserUniqueId();
    }
    return ClickstreamContext;
}());
export { ClickstreamContext };
