/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { ClickstreamProvider } from './provider';
var ClickstreamAnalytics = /** @class */ (function () {
    function ClickstreamAnalytics() {
    }
    /**
     * the init method for clickstream SDK
     * @param configure
     * @return the SDK initialize boolean result
     */
    ClickstreamAnalytics.init = function (configure) {
        if (this.provider !== undefined) {
            this.logger.warn('Clickstream SDK has initialized');
            return false;
        }
        this.provider = new ClickstreamProvider();
        this.provider.configure(configure);
        return true;
    };
    ClickstreamAnalytics.record = function (event) {
        this.provider.record(event);
    };
    ClickstreamAnalytics.setUserId = function (userId) {
        this.provider.setUserId(userId);
    };
    ClickstreamAnalytics.setUserAttributes = function (attributes) {
        this.provider.setUserAttributes(attributes);
    };
    ClickstreamAnalytics.updateConfigure = function (configure) {
        this.provider.updateConfigure(configure);
    };
    ClickstreamAnalytics.setGlobalAttributes = function (attributes) {
        this.provider.setGlobalAttributes(attributes);
    };
    ClickstreamAnalytics.logger = new Logger('ClickstreamAnalytics');
    return ClickstreamAnalytics;
}());
export { ClickstreamAnalytics };
