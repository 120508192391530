import { __awaiter, __generator } from "tslib";
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { Sha256 } from '@aws-crypto/sha256-browser';
var HashUtil = /** @class */ (function () {
    function HashUtil() {
    }
    HashUtil.getHashCode = function (str) {
        return __awaiter(this, void 0, void 0, function () {
            var hash, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hash = new Sha256();
                        hash.update(str);
                        return [4 /*yield*/, hash.digest()];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, this.uint8ArrayToHexString(result).substring(0, 8)];
                }
            });
        });
    };
    HashUtil.uint8ArrayToHexString = function (array) {
        return Array.from(array, function (byte) { return byte.toString(16).padStart(2, '0'); }).join('');
    };
    return HashUtil;
}());
export { HashUtil };
