/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { Logger } from '@aws-amplify/core';
import { BrowserInfo } from '../browser';
var logger = new Logger('BaseTracker');
var BaseTracker = /** @class */ (function () {
    function BaseTracker(provider, context) {
        this.provider = provider;
        this.context = context;
    }
    BaseTracker.prototype.setUp = function () {
        if (!BrowserInfo.isBrowser() ||
            !document.addEventListener ||
            !window.addEventListener ||
            !history.pushState) {
            logger.warn('not in the supported web environment');
        }
        else {
            this.init();
        }
    };
    return BaseTracker;
}());
export { BaseTracker };
